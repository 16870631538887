import { useContext } from 'react';
import { ObjectsWrapperManipulationContext } from '../ObjectsWrapper';
import FilterMenu from '../../../web/components/CementoComponents/FilterMenu';
import { FILTER_MENU_PATH_DELIMETER, FILTER_URL_KEY } from '../../../web/app/constants';
import TextFilter from '../../../web/views/Posts/TextFilter';
import ViewSelector from '../../../web/components/ViewSelector/ViewSelector';
import GroupsControls from './GroupsControls';

/**
 *
 * @param {{ viewType?: 'grid' | 'list' }} props
 * @returns
 */
const FilterBar = () => {
  const { groupBy, search, viewType, filterOptions, groupByOptions, setFilters, setGroupBy, setSearch, setViewType } =
    useContext(ObjectsWrapperManipulationContext);

  return (
    <div
      style={{
        display: 'flex',
        paddingLeft: '30%',
        paddingRight: '24px',
        alignItems: 'center',
      }}>
      <TextFilter
        containerStyle={{ margin: 12 }}
        defaultValue={search}
        onChange={setSearch}
        clearFilterVal={() => setSearch(null)}
      />
      <GroupsControls value={groupBy} onChange={setGroupBy} groupByOptions={groupByOptions} />
      <FilterMenu
        hideEmptyCategory={true}
        filters={filterOptions}
        filterUrlKey={FILTER_URL_KEY}
        buttonStyle={{ margin: 12 }}
        pathDelimeterOverwrite={FILTER_MENU_PATH_DELIMETER}
        onChange={setFilters}
      />
      <ViewSelector
        key={'PropAnalytics_ViewSelector'}
        activeViewType={viewType}
        onChange={(viewType) => setViewType(viewType)}
      />
    </div>
  );
};

export default FilterBar;
