import { getDispatch } from '../configureMiddleware';
import { getSnapshotData } from '../lib/utils/utils';
import { membersEvents } from './membersEvents';

export async function getUsersByProject(viewer, projectId) {
  const dispatch = getDispatch();
  const scopeParams = { scope: 'projects', scopeId: projectId };
  const resourceParams = {
    resourceName: 'users',
    firebasePath: 'users',
    queryParams: { includeGroups: true, projectId },
    forceMSClientConfig: true,
  };
  const onData = (data) => {
    const DBMembers = getDBMembers(data);
    dispatch({ type: membersEvents.SEARCH_PROJECT_USERS, payload: { DBMembers, projectId } });
  };

  const result = await getSnapshotData(scopeParams, resourceParams, onData, viewer);
  if (result) onData(result);
}

export function getDBMembers(usersMap) {
    let DBMembers = {};
    Object.values(usersMap || {}).forEach(user => { 
      if (user.user_metadata) {
        DBMembers[user.user_metadata.id] = user.user_metadata;
        DBMembers[user.user_metadata.id].activated = user.phone_verified;
        DBMembers[user.user_metadata.id].phoneNumber = user.user_metadata.phoneNumber && user.user_metadata.phoneNumber != "" ? 
        user.user_metadata.phoneNumber : user.phone_number;
      }
    });
    return DBMembers;
  }
  