import { firebaseStart } from '../lib/redux-firebase/actions';
import { updateMyUserMetadata } from '../users/actions';
import * as lastUpdatesActions from '../lastUpdates/actions';
import _ from 'lodash';
import _fp from 'lodash/fp';
import {
  getDifference,
  flattenObject,
  encodeBase64,
  getBase64StringInfo,
  splitInBatches,
  toJSDeep,
  onError,
} from './funcs';
import { uploadImage } from '../images/actions';
import { getRetryObjects, setRetryObjects, deleteRetryObjects } from '../lib/offline-mode/utils';
import { saveLocal, getLocal, removeNestedIsLocal, removeLocal, getMediaTypeFromExtension } from '../lib/utils/utils';
import { getAppState, writeLog } from '../configureMiddleware';
import { shouldUseBase64 } from './constants';
import { storeImagePermanently } from '../app/funcs';
import { deleteMultiLocalLastUpdates } from '../lastUpdatesV2/funcs';
import { platformActions } from '../platformActions';
import { updateUsingFirebaseProxy } from '../lib/api';
import DataManagerInstance from '../dataManager/DataManager';
import { SUBJECTS } from '../dataManager/subjects';
import { uploadVideo } from '../videos/funcs';

export const ERROR_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
};

export const APP_OFFLINE = 'APP_OFFLINE';
export const APP_ONLINE = 'APP_ONLINE';
export const APP_START = 'APP_START';
export const APP_STORAGE_LOAD = 'APP_STORAGE_LOAD';
export const SAVE_APP_STORAGE = 'SAVE_APP_STORAGE';
export const SET_LANG = 'SET_LANG';
export const SET_APP_INTL = 'SET_APP_INTL';
export const ON_CHECK_DB_SETTINGS = 'ON_CHECK_DB_SETTINGS';

export const HIDE_TOAST = 'HIDE_TOAST';
export const START_TOAST = 'START_TOAST';
export const START_ALERT = 'START_ALERT';
export const START_LOADING = 'START_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const HIDE_ALL_LOADING = 'HIDE_ALL_LOADING';
export const UPDATE_LAST_PROJECT_PAGE = 'UPDATE_LAST_PROJECT_PAGE';
export const CLEAN_CACHE = 'CLEAN_CACHE';
export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const GET_SETTING_LAST_UPDATE = 'GET_SETTING_LAST_UPDATE';
export const CLEAN_PERMISSION_BASED_DATA = 'CLEAN_PERMISSION_BASED_DATA';
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS';
export const CANCEL_OPERATION = 'CANCEL_OPERATION';
export const CONNECTION_MANAGER_UPDATE = 'CONNECTION_MANAGER_UPDATE';
export const UPDATE_CONNECTION_VIEWER_VISIBILITY = 'UPDATE_CONNECTION_VIEWER_VISIBILITY';
export const UPDATE_DATA_MANAGER_VISIBILITY = 'UPDATE_DATA_MANAGER_VISIBILITY';
export const UPDATE_CONFIGURATION_MODE = 'UPDATE_CONFIGURATION_MODE';
export const LOADING_TIMEOUT = 'LOADING_TIMEOUT';

export const SAVE_TO_SERVER = 'SAVE_TO_SERVER';
export const RETRY_SAVE_OBJECTS = 'RETRY_SAVE_OBJECTS';
export const SAVE_OBJECTS = 'SAVE_OBJECTS';
export const FIND_AND_UPLOAD_FILES = 'FIND_AND_UPLOAD_FILES';

export const UPLOAD_STATE_CURR_STATE = 'UPLOAD_STATE_CURR_STATE';
export const START_STATE_LOG_REQUESTS_LISTENER = 'START_STATE_LOG_REQUESTS_LISTENER';

export const LOADING_TIMEOUT_MS = 1000 * 45;

export function checkUpdateVersion() {
  return ({ dispatch, getState, platformActions }) => {
    const getPromise = async () => {
      let currVersion = platformActions.app.getVersion();

      return { appVersion: currVersion };
    };

    return {
      type: lastUpdatesActions.ON_CHECK_APP_VERSION,
      payload: getPromise(),
    };
  };
}

export function fetchGlobalConfigurations(viewer) {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      const connectionParams = {
        scope: 'global',
        viewer,
      };

      await Promise.all([
        DataManagerInstance.loadAndConnect({
          subject: SUBJECTS.CONFIGURATIONS,
          ...connectionParams,
          forceMSClientConfig: false,
        }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.PERMISSIONS, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.TITLES, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.TRADES, ...connectionParams }),
        DataManagerInstance.loadAndConnect({ subject: SUBJECTS.QUASI_STATICS, ...connectionParams }),
      ]);
    };

    return {
      type: ON_CHECK_DB_SETTINGS,
      payload: getPromise(),
    };
  };
}

export const legacyLoadMobileStorage = async ({ configKey, platform }) => {
  writeLog('info', 'Reading from legacy state', 'legacyLoadMobileStorage', {
    configKey,
    platform,
  });
  if (platform === 'ios') {
    return platformActions.storage.getItem(configKey);
  } else {
    const configName = 'cemento_' + configKey + '.cfg';
    const fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento/' + configName;
    const fileExist = await platformActions.fs.exists(fileLocation);
    if (fileExist) {
      return platformActions.fs.readFile(fileLocation, 'utf8');
    }
  }
};

var didStart = false;
export function start() {
  return ({ dispatch, platformActions, getState }) => {
    const getPromise = async () => {
      try {
        if (didStart) return { didStart };

        await DataManagerInstance.loadGlobalAppData();
        //await dispatch(checkUpdateVersion());
        let language = platformActions.app.getLang();
        var languageDidChanged = !getState().app.lang || language != getState().app.lang;

        dispatch(firebaseStart());

        var currLang = getState().app.lang || language;
        return { lang: currLang, didChanged: languageDidChanged };
      } catch (error) {
        console.log('start error: ' + error);
        throw error;
      }
    };

    return {
      type: APP_START,
      payload: getPromise(),
    };
  };
}

export function hideToast() {
  return {
    type: HIDE_TOAST,
  };
}

export function setAppIntl(intl) {
  return {
    type: SET_APP_INTL,
    payload: { intl },
  };
}

/**
 * @typedef {{ id: string, defaultMessage: string }} IntlMessage
 */
/**
 * @typedef {{ message: string | IntlMessage, onClick?: function, color?: 'success' | 'error' | 'danger',  }} Action
 * @typedef {{
 *  title: string | IntlMessage,
 *  values?: {[key: string]: string | IntlMessage},
 *  message?: string | IntlMessage,
 *  actions?: Action[]
 *  overlay?: boolean,
 *  mandatory?: boolean,
 *  overwriteTimeout?: boolean,
 *  type?: 'error' | 'success' | 'info'
 * }} StartToastParams
 * @param {StartToastParams} paramsObj
 */
export function startToast({ title, values, message, actions, overlay, mandatory, type, overwriteTimeout, close }) {
  return {
    type: START_TOAST,
    payload: {
      toast: { title, values, message, actions, overlay, mandatory, date: Date.now(), type, overwriteTimeout, close },
    },
  };
}

/**
 *
 * @param {string | IntlMessage} title
 * @param {string | IntlMessage} message
 * @param {{[key: string]: string}} values
 */
export function startAlert(title, message, values = undefined) {
  return {
    type: START_ALERT,
    payload: { alert: { message, title, date: Date.now(), messageValues: values, titleValues: values } },
  };
}

export function startLoading({
  title,
  values,
  overlay,
  hideOnBackgroundPress = true,
  operationId,
  cancelOnBackgroundPress = false,
  isWithTimeout = true,
}) {
  return ({ dispatch }) => {
    let payload = { startTS: Date.now(), overlay, hideOnBackgroundPress, cancelOnBackgroundPress, operationId };
    if (title) payload.toast = { title, values };

    if (isWithTimeout) setTimeout(() => dispatch(handleLoadingTimeOut(operationId)), LOADING_TIMEOUT_MS);

    return {
      type: START_LOADING,
      payload: payload,
    };
  };
}

export function hideLoading(operationId) {
  return {
    type: HIDE_LOADING,
    payload: { operationId },
  };
}

export function handleLoadingTimeOut(operationId) {
  return {
    type: LOADING_TIMEOUT,
    payload: { operationId },
  };
}

export function hideAllLoading(operationId) {
  return {
    type: HIDE_ALL_LOADING,
  };
}

export function saveStorage() {
  return {
    type: SAVE_APP_STORAGE,
  };
}

export function updateConnectionStatus(isConnected) {
  return {
    payload: { isConnected },
    type: UPDATE_CONNECTION_STATUS,
  };
}

export function setLang(lang, shouldUpdateServer) {
  return ({ platformActions, getState, dispatch }) => {
    try {
      if (getState && getState() && getState().getNested(['app', 'lang']) != lang) {
        platformActions.app.setRTLbyLang(lang);
        if (shouldUpdateServer) dispatch(updateMyUserMetadata('lang', lang));
      }

      return {
        type: SET_LANG,
        payload: { lang },
      };
    } catch (error) {
      throw error;
    }
  };
}

export function clearCache() {
  return {
    type: CLEAN_CACHE,
  };
}

export function cancelOperation(operationId) {
  return ({ dispatch }) => {
    dispatch(hideLoading(operationId));
    return {
      type: CANCEL_OPERATION,
      payload: { operationId },
    };
  };
}

export function downloadFile(url, uniqeName, ext, returnBase64, fetchParams) {
  return ({ platformActions }) => {
    const getPromise = async () => {
      // TODO: Check for cached items
      var fileLocation = null;
      var fileExist = false;
      if (uniqeName) {
        fileLocation = platformActions.fs.getCacheDirectoryPath() + '/' + (uniqeName || Date.now()) + '.' + ext;
        fileExist = await platformActions.fs.exists(fileLocation);
      }

      if (!fileExist) {
        const { params, config, legacyFetch } = fetchParams || {};
        var res = await platformActions.net.fetch(url, params, config, legacyFetch);
        let base64Str = res.data;
        if (returnBase64) return base64Str;
        await platformActions.fs.writeFile(fileLocation, base64Str, 'base64');
      }

      return fileLocation;
    };
    return {
      type: DOWNLOAD_FILE,
      payload: getPromise(),
    };
  };
}

export function cleanPermissionBasedData() {
  return ({ dispatch, getState }) => {
    const getPromise = async () => {
      let promises = [];

      const projectLastUpdateDocsToRemove = ['checklists/itemInstances', 'posts'];
      const projectCollectionsToRemove = ['checklistItemsInstances', 'posts'];

      let projectIdsArray = [];
      getState()
        .getNested(['projects', 'map'], {})
        .loopEach((k, p) => projectIdsArray.push(p.id));

      // Remove subject last update docs from storage -> so that it will be fetched again from server
      const lastUpdateDocsToDelete = [];
      projectIdsArray.forEach((projectId) => {
        projectLastUpdateDocsToRemove.forEach((subject) => {
          ['lastUpdates', 'revokes'].forEach((type) => {
            lastUpdateDocsToDelete.push({ scopeId: projectId, subject, type });
          });
        });
      });
      promises.push(deleteMultiLocalLastUpdates(lastUpdateDocsToDelete));

      // Remove subject collections from storage
      const cementoDB = platformActions.localDB.getCementoDB();
      projectCollectionsToRemove.forEach((collection) => promises.push(cementoDB.deleteCollection(collection)));

      await Promise.all(promises);
      DataManagerInstance.unsetProjectsData({ projectIds: projectIdsArray });
    };
    return {
      type: CLEAN_PERMISSION_BASED_DATA,
      payload: getPromise(),
    };
  };
}

export function clearALLData() {
  return ({ platformActions }) => {
    const getPromise = async () => {
      await platformActions.localDB.getCementoDB().deleteAll();

      if (platformActions.app.isAndroid()) {
        const fileLocation = platformActions.fs.getDocumentDirectoryPath() + '/cemento';
        await platformActions.fs.deletePath(fileLocation);
      } else {
        await platformActions.storage.clear();
      }
    };

    return {
      type: CLEAR_ALL_DATA,
      payload: getPromise(),
    };
  };
}

const seekAndCollectUriPathsArr = (object, isAlsoCheckData = false) => {
  if (!object) return [];

  const flatObject = flattenObject(object);
  return Object.entries(flatObject).reduce((acc, [key, val]) => {
    const splitKey = key.split('/');
    const lastKey = _.last(splitKey);

    if (
      lastKey === 'uri' ||
      (isAlsoCheckData && // !!UNTIL CEM-4481!! - Also check for data as temporary fix while we align files array with the rest of the system standard for storing files under "uri" property
        lastKey === 'data' &&
        typeof val === 'string' &&
        (val.startsWith('data:') || val.startsWith('file:/')))
    ) {
      const uriObjectPath = splitKey.slice(0, splitKey.length - 1);

      acc.push({
        uriObjectPathArr: uriObjectPath,
        uriObject: _.get(object, uriObjectPath),
        isFromDataProperty: lastKey === 'data',
      });
    }

    return acc;
  }, []);
};

export const findAndUploadFiles = async ({ objects, fileServerFolderName, targetFileNameBuilder }) => {
  let success = true;
  let hadFilesToUpload = false;
  let updatedObjects = {};

  if (Object.keys(objects || {}).length && fileServerFolderName && typeof targetFileNameBuilder === 'function') {
    for (const object of objects) {
      if (!object) return;
      let updatedObject = object;

      const uriPathObjects = seekAndCollectUriPathsArr(updatedObject, true);
      for (const uriPathObject of uriPathObjects) {
        const { uriObjectPathArr, uriObject, isFromDataProperty } = uriPathObject;
        const { uri: fileUri, type: fileContentType, data: fileDataUri } = uriObject;

        const fileUriString = isFromDataProperty ? fileDataUri : fileUri;
        if (!fileUriString || (fileUriString.startsWith && fileUriString.startsWith('http'))) continue;

        if (!hadFilesToUpload) hadFilesToUpload = true;

        const extension = fileContentType || fileUriString.split('.').pop();
        const uri = shouldUseBase64()
          ? !getBase64StringInfo(fileUriString)
            ? await encodeBase64(fileUriString, fileContentType)
            : fileUriString
          : await storeImagePermanently(fileUriString, undefined, extension);

        let fileUrl = null;
        let isUploadFailed = null;
        try {
          const mediaTypes = getMediaTypeFromExtension(extension);
          const uploadFunc = mediaTypes.isImage
            ? () =>
                uploadImage(
                  uri,
                  targetFileNameBuilder(updatedObject, { uriObject, uriPathInObject: uriObjectPathArr }),
                  fileServerFolderName
                )
            : () =>
                uploadVideo(
                  { ...uriObject, uri },
                  targetFileNameBuilder(updatedObject, { uriObject, uriPathInObject: uriObjectPathArr }),
                  fileServerFolderName
                );

          fileUrl = await uploadFunc();
          isUploadFailed = !(typeof fileUrl === 'string' && fileUrl.startsWith('http'));
        } catch (error) {
          console.warn(FIND_AND_UPLOAD_FILES + ' failed uploading form signature file', {
            error,
            updatedObject,
            fileUri: fileUriString,
            fileContentType,
            base64FileString: uri,
          });
          isUploadFailed = true;
        }

        if (isUploadFailed && success) success = false;

        updatedObject = _fp.set(
          [...uriObjectPathArr, isFromDataProperty ? 'data' : 'uri'],
          isUploadFailed ? uri : fileUrl,
          updatedObject
        );
      }

      updatedObjects[updatedObject.id] = updatedObject;
    }
  } else updatedObjects = objects;

  return { hadFilesToUpload, updatedObjects, success };
};

let pendingUpdatesCommands = {};
export const saveToServer = ({
  objectsToSave,
  originalObjects,
  dbRootPathArr,
  fileServerFolderName,
  targetFileNameBuilder,
  callback,
  skipPendingQueuCheck = true,
  objectType,
  projectId,
  sdkUploadFunc,
}) => {
  return ({ platformActions }) => {
    const getPromise = async () => {
      if (!Object.keys(objectsToSave || {}).length || !(dbRootPathArr || []).length)
        return { success: false, objectsToSave };

      const {
        hadFilesToUpload,
        updatedObjects,
        success: uploadFilesSuccess,
      } = await findAndUploadFiles({ objects: objectsToSave, fileServerFolderName, targetFileNameBuilder });

      if (hadFilesToUpload) objectsToSave = updatedObjects;

      const enhancedCallback = (fullSuccess, successReason) => {
        if (callback && typeof callback === 'function')
          callback({ success: fullSuccess, hadFilesToUpload, objectsToSave, successReason });
      };

      let dbUpdates = {};
      if (!hadFilesToUpload || uploadFilesSuccess) {
        Object.values(objectsToSave).forEach((objectToSave) => {
          const currObjectId = Boolean(objectToSave) && objectToSave.id;

          if (!currObjectId) {
            platformActions.sentry.notify(`Missing objectId for save to db`, {
              objectsToSave,
              originalObjects,
              dbRootPathArr,
            });
            console.warn(`Missing objectId for save to db`, { objectsToSave, originalObjects, dbRootPathArr }); // TODO: Send to bugsnag
            return;
          }

          objectToSave = _.cloneDeep(objectToSave);
          objectToSave = removeNestedIsLocal(objectToSave, false);
          // objectToSave = removeEmpty(objectToSave);
          delete objectToSave.updatedTS;
          const dbRootPath = [...dbRootPathArr, currObjectId].join('/');
          dbUpdates[dbRootPath] = objectToSave;
        });

        // This whole pendingUpdatesCommands and callback thing is a work arround until we update react-native firebase to v6 and we are able to disable the persistence setting
        // This persistence thing means that firebase.update does not call its callback until the operation is successful and it will keep the update in memory until it succeeds and keep on retrying
        if (!skipPendingQueuCheck) dbUpdates = _.omit(dbUpdates, Object.keys(pendingUpdatesCommands));

        const isConnected = _.isFunction(getAppState) && getAppState().getNested(['app', 'isConnected'], false);
        const dbUpdatesKeys = Object.keys(dbUpdates);
        if (!isConnected) {
          enhancedCallback(false, { message: 'No connection' });
        } else if (dbUpdatesKeys.length) {
          if (sdkUploadFunc) {
            try {
              await sdkUploadFunc(Object.values(dbUpdates));
              enhancedCallback(true, { message: 'It just worked :)' });
            } catch (err) {
              enhancedCallback(false, { message: 'Save failed', sdkError: err });
            }
          } else {
            const timeout = setTimeout(() => enhancedCallback(false, { message: 'Save timed out' }), 30000);
            dbUpdatesKeys.forEach((updateKey) => (pendingUpdatesCommands[updateKey] = true));
            const uploadPromise = new Promise(async (resolve) => {
              let error = null;

              if (dbUpdatesKeys.length > 500) {
                const updateBatchesArr = splitInBatches(dbUpdates, 100);
                await Promise.all(
                  updateBatchesArr.map(async (updateBatch) => {
                    if (error) return;

                    return await updateUsingFirebaseProxy({
                      projectId,
                      type: objectType,
                      updates: updateBatch,
                      callback: (e) => e && (error = e),
                    });
                  })
                );
              } else
                await updateUsingFirebaseProxy({
                  projectId,
                  type: objectType,
                  updates: dbUpdates,
                  callback: (e) => e && (error = e),
                });

              resolve(error);
            });

            uploadPromise.then((error) => {
              clearTimeout(timeout);
              const fullSuccess = !error;
              enhancedCallback(fullSuccess, {
                message: fullSuccess ? 'It just worked :)' : 'Some error occured in firebase.update',
                firebaseError: error,
              });
              pendingUpdatesCommands = _.omit(pendingUpdatesCommands, dbUpdatesKeys);
            });
          }
        } else {
          enhancedCallback(true, { message: 'No update commands to perform' });
        }
      } else {
        enhancedCallback(false, { message: 'Had files to upload and it failed to upload them' });
      }

      return {
        objectsToSave,
        uploadFilesSuccess,
        hadFilesToUpload,
        dbUpdates,
        // success: Boolean(uploadDBsuccess && uploadFilesSuccess),
      };
    };

    return {
      type: SAVE_TO_SERVER,
      payload: getPromise(),
    };
  };
};

/**
 * @typedef  SaveObjectsParams
 * @property {Object<string, Object<string, any>> | Array<Object<string, any>>} objectsToSave
 * @property {Object<string, Object<string, any>> | Array<Object<string, any>>} originalObjects
 * @property {boolean} [isRetry]
 * @property {string} schemaName
 * @property {string} schemaType
 * @property {string} lastUpdateTStypeId
 * @property {boolean} allowOfflineActivity
 * @property {Array<string>} dbRootPathArr
 * @property {string} projectId
 * @property {string} [fileServerFolderName]
 * @property {function({ projectId: string }):Array<string>} [targetFileNameBuilder]
 * @property {import('../lib/offline-mode/config').PreProcessObjectForLocalSaveFunc} [preProcessObjectForLocalSaveFunc]
 * @property {Array<string>} [serverOnlyFields]
 * @property {boolean} [skipUploadToServer]
 * @property {import('../lib/offline-mode/config').ProcessRealmObjectsOutputFunc} [processRealmObjectsOutputFunc]
 * @property {(objects: Array<any>) => Promise<any>} [sdkUploadFunc]
 */

const handleSdkError = ({ error, projectId, objects }) => {
  let shouldSendToRetry = true;

  switch (error.code) {
    case ERROR_CODES.BAD_REQUEST:
    case ERROR_CODES.UNAUTHORIZED:
    case ERROR_CODES.PAYMENT_REQUIRED:
    case ERROR_CODES.FORBIDDEN:
    case ERROR_CODES.NOT_FOUND:
    case ERROR_CODES.METHOD_NOT_ALLOWED:
    case ERROR_CODES.NOT_ACCEPTABLE:
    case ERROR_CODES.PROXY_AUTHENTICATION_REQUIRED:
    case ERROR_CODES.CONFLICT:
      shouldSendToRetry = false;
      onError({
        errorMessage: 'Failed to upload local objects',
        errorCode: error.code,
        error,
        methodMetaData: { projectId, instances: objects },
        errorMetaData: { originalErrorMessage: error.message },
      });
      break;
  }

  return shouldSendToRetry;
};

/**
 * Saves objects to the server and locally if supported
 * @param {SaveObjectsParams} paramsObj
 */
export const saveObjects = ({
  objectsToSave,
  originalObjects,
  isRetry = false,
  schemaName,
  schemaType,
  lastUpdateTStypeId,
  allowOfflineActivity,
  dbRootPathArr,
  projectId,
  fileServerFolderName,
  targetFileNameBuilder,
  preProcessObjectForLocalSaveFunc,
  serverOnlyFields,
  skipUploadToServer = false,
  processRealmObjectsOutputFunc,
  sdkUploadFunc,
}) => {
  return ({ dispatch }) => {
    /** @returns {Promise<{ success: boolean }>} */
    const getPromise = () =>
      new Promise(async (resolve, reject) => {
        objectsToSave = Object.values(objectsToSave || {});
        if (!(objectsToSave.length && projectId && (dbRootPathArr || []).length)) return;

        const saveObjectsLocally = (objectsToSaveLocally, isAfterUpload) => {
          if (allowOfflineActivity || isAfterUpload)
            saveLocal({
              lastUpdateTStypeId,
              projectId,
              schemaName,
              schemaType,
              objectsToSave: Object.values(objectsToSaveLocally).map((o) => ({ ...o, isLocal: true })),
              preProcessObjectForLocalSaveFunc,
            });
        };

        if (!Object.keys(originalObjects || {}).length) {
          const idsToGet = objectsToSave.map((objectToSave) => objectToSave.id);
          const retryObjects = getRetryObjects({ objectType: lastUpdateTStypeId, projectId }).retryObjects;
          const localObjects = getLocal({ idsToGet, projectId, schemaName, schemaType }).objects;
          originalObjects = {};
          Object.values(retryObjects).forEach(
            (o) => !originalObjects[o.id] && idsToGet.includes(o.id) && (originalObjects[o.id] = o)
          );
          Object.values(localObjects).forEach((o) => !originalObjects[o.id] && (originalObjects[o.id] = o));
          originalObjects = Object.values(originalObjects);

          if (typeof processRealmObjectsOutputFunc === 'function')
            originalObjects = Object.values(originalObjects || {}).map(processRealmObjectsOutputFunc);
        }

        if (!isRetry) saveObjectsLocally(objectsToSave.map((o) => ({ ...o, lastUploadTS: 0 })));

        const saveToServerCallback = ({
          success: objectSavedToDBSuccess,
          hadFilesToUpload,
          objectsToSave: updatedObjectsToSave,
          sdkError,
          ...rest
        }) => {
          const objectsToSaveByIdMap = objectsToSave.reduce(
            (acc, objectToSave) => _.set(acc, [objectToSave.id], objectToSave),
            {}
          );
          objectsToSave = Object.values(updatedObjectsToSave || {}).map((updatedObjectToSave) => ({
            ...objectsToSaveByIdMap[updatedObjectToSave.id],
            ...updatedObjectToSave,
          })); // some properties were removed before save to server that we DO want to have locally, so here we make sure they are there before we save local

          if (sdkError) {
            const shouldSendToRetry = handleSdkError(sdkError);
            if (!shouldSendToRetry) {
              deleteRetryObjects({ retryObjects: objectsToSave, projectId });
              if (_.size(originalObjects)) {
                saveObjectsLocally(originalObjects, true);
              } else {
                removeLocal({ objectsToRemove: objectsToSave, projectId, schemaType });
              }
              return;
            }

            if (hadFilesToUpload) {
              saveObjectsLocally(objectsToSave);
              if (allowOfflineActivity && !objectSavedToDBSuccess) {
                setRetryObjects({ retryObjects: objectsToSave, objectType: lastUpdateTStypeId, projectId });
              }
            }

          }
          if (objectSavedToDBSuccess) {
            const lastUploadTS = Date.now();
            saveObjectsLocally(
              objectsToSave.map((o) => ({ ...o, lastUploadTS })),
              true
            );
            deleteRetryObjects({ retryObjects: originalObjects, projectId });
          } else if (allowOfflineActivity && !isRetry)
            setRetryObjects({ retryObjects: originalObjects, objectType: lastUpdateTStypeId, projectId });
          else if (!allowOfflineActivity && !isRetry) {
            // also check for isRetry just in case we do get to this point without offline ability (should never happen)
            // TODO: start toast saying that the user can't do that
          }

          const isOperationSuccess = objectSavedToDBSuccess || (allowOfflineActivity && !objectSavedToDBSuccess);
          resolve({ success: isOperationSuccess });
        };

        if (!skipUploadToServer) {
          const pickFields = (objects) =>
            serverOnlyFields ? Object.values(objects || {}).map((o) => _.pick(o, serverOnlyFields)) : objects; // Filter object's properties to only return the ones that actually need to be saved to server
          dispatch(
            saveToServer({
              objectsToSave: pickFields(objectsToSave),
              originalObjects: pickFields(originalObjects),
              dbRootPathArr,
              fileServerFolderName,
              targetFileNameBuilder,
              callback: saveToServerCallback,
              skipPendingQueuCheck: !isRetry,
              objectType: schemaType,
              projectId,
              sdkUploadFunc,
            })
          );
        } else saveToServerCallback({ success: false, hadFilesToUpload: false, objectsToSave });
      });

    return {
      type: isRetry ? RETRY_SAVE_OBJECTS : SAVE_OBJECTS,
      payload: getPromise(),
    };
  };
};

/**
 *
 * @param {{
 *  viewerId: string;
 *  paths: string[][]
 *  logId?: string;
 * }} param0
 */
export const uploadStateCurrState = ({ viewerId, paths = [] }) => {
  return ({ getState, firebase }) => {
    const getPromise = async () => {
      const state = getState();
      const ts = new Date();
      if (!state || !paths.length) {
        return;
      }

      let log = {};
      paths.forEach((path) => {
        const pathValue = state.getNested(path, null);
        log[path.join('_')] = pathValue;
      });

      if (!_.isEmpty(log)) {
        log = toJSDeep(log);
        log.timestamp = ts.toISOString();
        const logPath = `_internal/logs/membersState/${viewerId}/${platformActions.app.getPlatform()}/${ts}`;
        await firebase.update({ [logPath]: log });
      }
    };

    return {
      type: UPLOAD_STATE_CURR_STATE,
      payload: getPromise(),
    };
  };
};

export const startStateLogRequestsListener = (() => {
  let prevViewer = null;
  let fbRef = null;

  return (viewer) => {
    return ({ firebase, firebaseDatabase, dispatch }) => {
      if (viewer && (!prevViewer || viewer.id !== prevViewer.id || !fbRef)) {
        prevViewer = viewer;
        const basePath = `_internal/logs/membersStateRequests/${viewer.id}`;
        fbRef = firebaseDatabase().ref(basePath);
        const handleListener = async (snapshot) => {
          const { paths } = snapshot.val();
          await dispatch(uploadStateCurrState({ viewerId: viewer.id, paths }));
          await firebase.update({ [`${basePath}/${snapshot.key}`]: null });
        };

        fbRef.on('child_added', handleListener);
        fbRef.on('child_changed', handleListener);
      }

      return {
        type: START_STATE_LOG_REQUESTS_LISTENER,
        payload: {
          off: () => {
            if (fbRef) {
              fbRef.off('child_added');
              fbRef.off('child_changed');
            }
          },
        },
      };
    };
  };
})();

export function connectionManagerUpdate({ status, size, scope, scopeId, subject, subjectName }) {
  return {
    type: CONNECTION_MANAGER_UPDATE,
    payload: { status, size, scope, scopeId, subject, subjectName },
  };
}

export function updateConnectivityViewerVisiblity({ visible = false }) {
  return {
    type: UPDATE_CONNECTION_VIEWER_VISIBILITY,
    payload: { visible },
  };
}

export function updateDataManagerVisibility({ visible = false }) {
  return {
    type: UPDATE_DATA_MANAGER_VISIBILITY,
    payload: { visible },
  };
}

export function updateAppConfigurationMode({ mode = false }) {
  return {
    type: UPDATE_CONFIGURATION_MODE,
    payload: { mode },
  };
}
