import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import { startToast } from "../../../common/app/actions";
import { connectContext } from "react-connect-context";
import Select from "react-select";
import { checkError } from "../../../common/propertiesTypes/funcs";
import withStyles from "@material-ui/core/styles/withStyles";
import { ProjectContext } from "../../../common/projects/contexts";
import { uploadPropertiesInstances } from "../../../common/propertiesInstances/actions";
import InputField from "../../components/CementoComponents/InputField";
import Text from "../../components/CementoComponents/Text";
import systemMessages from "../../../common/app/systemMessages";
import theme from "../../assets/css/theme";
import MenuScrollbar from "../../components/CementoComponents/MenuScrollbar";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import _ from "lodash";
import _fp from "lodash/fp";
import * as propertyTypes from "../../../common/propertiesTypes/propertiesTypes";
import { UPLOAD_FILE } from "../../../common/propertiesTypes/propertiesTypes";

const ENABLE_CERTIFICATION_MODAL_WIZARD = false;
const isCertificatePermittedEditOnWeb = (subjectPropertiesTypes, propId) => {
  const prop = _.get(subjectPropertiesTypes, [propId]);
  return Boolean(
    !_.get(prop, ["settings", "signatureBehaviour"]) ||
      _.get(
        prop,
        ["settings", "signatureBehaviour", UPLOAD_FILE, "enabled"],
        false
      ) ||
      _.get(prop, ["extraTypes"], []).some((extraPropId) =>
        [
          propertyTypes.FILES_ARRAY,
          propertyTypes.DRAWINGS_ARRAY,
          propertyTypes.PDF,
          propertyTypes.PICTURE,
        ].includes(_.get(subjectPropertiesTypes, [extraPropId, "type"]))
      )
  );
};

class AddNewCertification extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.handleBackPress = this.handleBackPress.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCertificationTypeSelect = this.onCertificationTypeSelect.bind(this);
    this.state = {
      updates: {},
      options: [],
      errors: [],
      hideTypesSelect: Boolean(props.initialCertificationProp),
      selectedCertificationType: props.initialCertificationProp
        ? props.initialCertificationProp
        : null,
    };
  }

  UNSAFE_componentWillMount() {
    this.setComponentData({ firstMount: true }, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps);
  }

  setComponentData(props, nextProps) {
    let newStateChanges = {};

    if (
      props.certificationTypes != nextProps.certificationTypes &&
      nextProps.certificationTypes
    ) {
      newStateChanges.options = [];
      nextProps.certificationTypes.loopEach((id, prop) => {
        if (
          isCertificatePermittedEditOnWeb(
            _.get(nextProps, ["propertiesTypes", nextProps.subjectName], {}),
            prop.id
          )
        )
          newStateChanges.options.push({
            id: prop.id,
            label: prop.getCementoTitle(),
          });
      });
    }

    if (Object.keys(newStateChanges).length > 0) this.setState(newStateChanges);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.hasCertificationTTL != this.state.hasCertificationTTL ||
      prevState.isRoleAppointment != this.state.isRoleAppointment
    )
      this.handleCardLoad();
  }

  componentDidMount() {
    this.handleCardLoad();
  }
  componentWillUnmount(){
    const { onCardLoad } = this.props;
    const editMode = false
    onCardLoad(
      {},
      {},
      editMode, // disable edit mode on unmount
    );
  }

  handleCardLoad() {
    const { onCardLoad, addMode = true, subjectName,  originalEditMode } = this.props;
    const { hasCertificationTTL, isRoleAppointment } = this.state;

    let headerOptions = {
      editable: Boolean(hasCertificationTTL || isRoleAppointment),
      onSave: () => this.handleDone(),
    };
    onCardLoad(
      headerOptions,
      {},
      originalEditMode,
      addMode,
      propertiesMessages.certification[subjectName]
    );
  }

  onCertificationTypeSelect(selected) {
    const { propertiesTypes, subjectName } = this.props;
    const isRoleAppointment = Boolean(
      propertiesTypes.getNested([
        subjectName,
        selected.id,
        "settings",
        "certificationType",
      ]) == propertyTypes.CERTIFICATIONS_TYPES.roleAppointment
    );
    let certData = isRoleAppointment ? [{ signatureTS: Date.now() }] : null;

    this.setState({
      isRoleAppointment,
      certData,
      selectedCertificationType: propertiesTypes.getNested([
        subjectName,
        selected.id,
      ]),
      selected,
    });
  }

  onUpdate(newVal) {
    newVal = newVal || [];
    let lastCert = newVal[newVal.length - 1] || {};
    this.setState({
      certData: newVal,
      hasCertificationTTL: Boolean(lastCert.certificationTTL),
    });
  }

  handleBackPress() {
    const { onClose } = this.props;
    const { certData } = this.state;
    if (onClose) onClose(Boolean(certData));
  }

  handleDone() {
    const { startToast, onDone, propertiesTypes, propertiesSections, subjectName, intl, selectedProjectId } =
      this.props;
    const { selectedCertificationType, certData } = this.state;

    if (selectedCertificationType) {
      let errors = checkError(
        certData,
        selectedCertificationType,
        propertiesSections[subjectName] || {},
        propertiesTypes[subjectName],
        intl,
        selectedProjectId,
      );
      if (errors) {
        startToast({
          title: systemMessages.invalidDetailsDescription,
          values: { errors: Object.values(errors).join("\n") },
        });
        this.setState({ errors });
        return false;
      } else if (onDone) {
        let certDataToSave = certData;
        if (
          _.values(
            _.get(this.props, [
              "propertiesTypes",
              subjectName,
              (selectedCertificationType || {}).id,
              "settings",
              "signatureBehaviour",
            ])
          ).length
        )
          certDataToSave = _fp.set(
            [certDataToSave.length - 1, "signatureBehaviour"],
            UPLOAD_FILE,
            certDataToSave
          );

        return onDone(selectedCertificationType.id, certDataToSave);
      }
    }
  }

  render() {
    const {
      certData,
      selectedCertificationType,
      options,
      hideTypesSelect,
      errors,
      selected,
    } = this.state;
    const { addMode, intl, subjectName, instancesByPropertyId, classes, rtl } =
      this.props;

    let cert = this.props.getNested(
      ["propertiesTypes", subjectName, (selectedCertificationType || {}).id],
      {}
    );
    let headerTextStyle = {
      ...theme.subFont,
      color: "#2e231d",
      fontSize: theme.mediumFontSize,
      fontWeight: theme.strongBold,
      lineHeight: theme.mediumFontSize + "px",
      width: "auto",
      [rtl ? "marginLeft" : "marginRight"]: theme.paddingSize,
    };
    let headerSectionStyle = {
      alignItems: "center",
      margin: "25px 0px",
      color: theme.brandPrimary,
      fontFamily: "Assistant - Semi Bold",
      fontSize: 16,
      fontWeight: theme.strongBold,
      [rtl ? "marginLeft" : "marginRight"]: 3,
    };

    let extraTypesProps = null;
    if (cert.extraTypes && cert.extraTypes.length)
      extraTypesProps = (cert.extraTypes || []).map(
        ((extraPropId) =>
          this.props.getNested(
            ["propertiesTypes", subjectName, extraPropId],
            {}
          )).bind(this)
      );

    return (
      <MenuScrollbar isSmooth={true}>
        <div
          className={classes.cardSectionsStyles}
          style={{
            transition: "all 150ms ease 0s",
            backgroundColor: theme.backgroundColorBright,
          }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            {Boolean(errors && errors.length) && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  marginTop: theme.margin,
                  marginBottom: theme.margin,
                  alignItems: "center",
                }}
              >
                {errors.map((e) => (
                  <Text
                    style={{
                      alignSelf: "center",
                      marginBottom: 2,
                      marginTop: 2,
                      color: theme.brandDanger,
                    }}
                  >
                    {e}
                  </Text>
                ))}
              </div>
            )}

            {Boolean(!hideTypesSelect) && (
              <Select
                placeholder={intl.formatMessage(
                  propertiesMessages.selectCectification
                )}
                isSearchable={true}
                defaultValue={selected}
                onChange={this.onCertificationTypeSelect}
                styles={selectStyles}
                options={options}
              />
            )}
            <InputField
              key={cert.id + "_add_new_cert_comp"}
              id={cert.id + "_add_new_cert_comp"}
              style={{ flex: 1, flexDirection: "row", width: "100%" }}
              mode={"card"}
              prop={cert}
              extraTypes={cert.extraTypes}
              extraTypesProps={extraTypesProps}
              addMode={addMode}
              disabled={false}
              renderIndependently={true}
              propId={
                selectedCertificationType ? selectedCertificationType.id : null
              }
              settings={cert.settings}
              businessType={cert.businessType}
              defaultValue={
                certData
                  ? certData
                  : instancesByPropertyId.getNested([cert.id, "data"])
              }
              updatedTS={
                instancesByPropertyId
                  ? instancesByPropertyId.getNested([cert.id, "updatedTS"]) ||
                    instancesByPropertyId.getNested([cert.id, "createdTS"])
                  : null
              }
              onChange={this.onUpdate}
              type={cert.type}
            />
          </div>
        </div>
      </MenuScrollbar>
    );
  }
}

let styles = {
  postImageSize: 160,
  cardSectionsStyles: {
    padding: theme.paddingSize + "px 50px",
    boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 20px 0px",
    marginBottom: theme.paddingSize,
    display: "flex",
    flexDirection: "column",
  },
};

const selectStyles = {
  input: (styles) => ({
    ...styles,
    ...theme.defaultFont,
    fontWeight: 500,
    fontSize: "20px",
    color: theme.brandNeutralDark,
    zIndex: theme.zIndexes.newCertificateInput,
  }),
  singleValue: (styles) => ({
    ...styles,
    ...theme.defaultFont,
    fontWeight: 500,
    fontSize: "20px",
    color: theme.brandNeutralDark,
    zIndex: theme.zIndexes.newCertificateInput,
  }),
  container: (styles) => ({
    ...styles,
    width: "100%",
    border: "0px",
    borderRadius: "0px",
    zIndex: theme.zIndexes.newCertificateInput,
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "0px",
    boxShadow: "0px",
    cursor: "pointer",
    zIndex: theme.zIndexes.newCertificateInput,
  }),
  indicatorSeparator: (styles) => ({ visibility: "hidden" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: theme.zIndexes.newCertificateInput,
      backgroundColor: theme.backgroundColorBright,
      color: isDisabled
        ? "#ccc"
        : isSelected || isFocused
        ? theme.brandPrimary
        : theme.brandNeutralDark,
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
  menuList: (styles) => ({ ...styles, zIndex: theme.zIndexes.newCertificateInput, borderRadius: "0px" }),
  menu: (styles) => ({ ...styles, zIndex: theme.zIndexes.newCertificateInput, borderRadius: "0px" }),
};

AddNewCertification = withStyles(styles)(AddNewCertification);
AddNewCertification = injectIntl(AddNewCertification);
const enhance = compose(
  connectContext(ProjectContext.Consumer),
  connect(
    (state) => ({
      rtl: state.app.rtl,
    }),
    {
      uploadPropertiesInstances,
      startToast,
    }
  )
);
export default enhance(AddNewCertification);
