import analyticsMessages from '../../../common/analytics/analyticsMessages';
import { POSTS_VIEW_TYPES } from '../../app/constants';
import theme from '../../assets/css/theme';

import selectedTableIcon from '../../assets/img/header/table_view_selected.png';
import tableIcon from '../../assets/img/header/table_view.png';
import selectedCardIcon from '../../assets/img/header/cards_view_selected.png';
import cardIcon from '../../assets/img/header/cards_view.png';
import { useSelector } from 'react-redux';
import Text from '../CementoComponents/Text';
import _ from 'lodash';

const ViewSelector = ({ activeViewType, onChange }) => {
  const { rtl } = useSelector((state) => ({ rtl: state.app.rtl }));

  const buttonStyle = {
    display: 'flex',
    border: '1.5px solid #dee0e5',
    borderRadius: '4px',
    padding: theme.padding,
    minWidth: 'auto',
    alignItems: 'center',
    height: 33,
    cursor: 'pointer',
    padding: `${theme.padding - 3}px ${theme.padding * 2 - 3}px`,
  };
  const textStyle = {
    idle: {
      color: '#3C4858',
    },
    active: {
      color: '#F77D04',
    },
  };

  const iconStyle = {
    height: theme.fontSizeH5,
    margin: theme.verticalMargin / 2,
  };

  const icons = {
    [POSTS_VIEW_TYPES.TABLE]: {
      selected: selectedTableIcon,
      nonSelected: tableIcon,
    },
    [POSTS_VIEW_TYPES.TILES]: {
      selected: selectedCardIcon,
      nonSelected: cardIcon,
    },
  };

  return [POSTS_VIEW_TYPES.TILES, POSTS_VIEW_TYPES.TABLE].map((viewType, index) => {
    const isSelected = activeViewType === viewType;
    return (
      <div
        key={'button' + viewType}
        style={{
          ...buttonStyle,
          ...((rtl && index === 0) || (index !== 0 && !rtl)
            ? {
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
              }
            : {
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
              }),
        }}
        onClick={() => onChange(viewType)}>
        <img src={_.get(icons, [viewType, isSelected ? 'selected' : 'nonSelected'])} style={iconStyle} alt='...' />
        <Text style={isSelected ? textStyle.active : textStyle.idle}>
          {analyticsMessages.posts.viewSelector[viewType]}
        </Text>
      </div>
    );
  });
};

export default ViewSelector;
